import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "output" ]

  show_order_in_modal_view(event) {
    var order_number = event.target.dataset.orderNumber
    var url = event.target.dataset.url
    var loadingOrderMessage = event.target.dataset.loadingOrderMessage
    this.data.set("number", order_number)
    this.outputTarget.innerHTML = loadingOrderMessage
    $.ajax(url, {
      method: 'GET'
    }).then(response => {
      this.outputTarget.innerHTML = response
    })
  }
}
